import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import( '@/views/HomeView.vue')
    },
    {
        path: '/ticket',
        name: 'ticket',
        component: () => import( '@/views/TicketView.vue')
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import( '@/views/PaymentView.vue')
    },
    {
        path: '/loyalty',
        name: 'loyalty',
        component: () => import( '@/views/LoyaltyView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router