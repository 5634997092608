import {createStore} from 'vuex'

export default createStore({
    state: {
        discount: 0,
    },
    getters: {
        getDiscount(state) {
            return state.discount;
        }
    },
    mutations: {
        setDiscount(state, discount) {
            state.discount = discount;
        }
    },
    actions: {},
    modules: {}
})
