<template>
  <div class="container">
    <div class="menu" v-if="menu">
      <a href="#" class="menu--close" @click="menu=!menu"><img src="/assets/close.svg"></a>
      <div class="mb-5">
        Тех. поддержка:<br>
        info@planix.app
      </div>
      <div class="menu--content">
        <a href="#" @click.prevent="b1=!b1">Как оплатить парковку</a>
        <ul class="mb-3" v-if="b1">
          <li>Откройте камеру на своём мобильном устройстве.</li>
          <li>При необходимости, переведите камеру в режим сканирования QR-кода.</li>
          <li>Наведите камеру на QR-код, распечатанный на билете или расположенный на информационных стойках парковки.
          </li>
          <li>Перейдите по ссылке, распознанной камерой.</li>
          <li>В случае сканирования QR-кода с информационных стоек парковки, введите номер билета или номер Вашего
            автомобиля.
          </li>
          <li>Если по билету требуется оплата, выберите удобный для Вас способ.</li>
          <li>Следуйте инструкциям на странице платежа для его завершения.</li>
          <li>После оплаты Вы будете перенаправлены на страницу с информацией о произведенной оплате.</li>
          <li>В случае успешной оплаты произведите выезд с парковки не позднее указанного времени.</li>
        </ul>
        <a href="#" @click.prevent="b2=!b2">Способы оплаты</a>
        <div class="mb-3" v-if="b2">
          Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку
          Оплата заказа банковской картой. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт
          следующих платёжных систем:
          МИР
          Visa
          MasterCard
          JCB
          Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение
          с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола
          шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей
          Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может
          потребоваться ввод специального пароля.
          Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации
          обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев,
          предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом
          соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.
        </div>
        <a href="#" @click.prevent="b3=!b3">Не удается оплатить парковку?</a>
        <div class="mb-3" v-if="b3">
          Если у Вас не получается оплатить парковку, попробуйте повторить или напишите нам на электронную почту:
          Опишите трудности, которые у вас возникли.
          Приложите скриншот ошибки или страницы, на которой возникла проблема.
          Если знаете, укажите название и версию используемого браузера.
        </div>
        <a href="#" @click.prevent="b4=!b4">Сделали ошибочный платеж?</a>
        <div class="mb-3" v-if="b4">
          Если Вы произвели ошибочный платёж напишите нам на электронную почту:
          Опишите обстоятельства, при которых был произведен ошибочный платёж
          Приложите выписку об оплате из личного кабинета мобильного банка
          Заявка на возврат рассматривается в течение 5 рабочих дней. Возврат переведённых средств, производится на ваш
          банковский счёт в течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).
        </div>
        <a href="#" @click.prevent="b5=!b5">Бонусная система</a>
        <div class="mb-3" v-if="b5">
          Оплата парковки так же может осуществляться путем прикрепления чека оплаты в любом из
          магазинов внутри ТРЦ РИО, чек на сумму в 3000 рублей ровняется 100 парковочным баллам (1 балл = 1 рубль).
        </div>
      </div>
    </div>
    <div class="header">
      <router-link to="/"><img src="/assets/logo.png"></router-link>
      <div>
        <a href="tel:+79099907961" class="me-3"><img src="/assets/call.png" alt="Call"></a>
        <a href="#" @click="menu=!menu"><img src="/assets/burger.png" alt="Menu"></a>
      </div>
    </div>
    <div class="hero"></div>
    <div class="title">ОПЛАТА ПАРКОВОЧНОГО МЕСТА<br> ТРЦ "РИО" ДМИТРОВКА</div>
    <div class="dots">
      <div class="dot dot--active">1</div>
      <div class="dot">2</div>
      <div class="dot">3</div>
      <div class="dot">4</div>
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: false,
      b1: false,
      b2: false,
      b3: false,
      b4: false,
      b5: false,
    }
  },
}
</script>