import {createApp} from 'vue'
import App from '@/App.vue'
import router from '@/router.js'
import store from '@/store.js'
import axios from 'axios';
import VueTheMask from 'vue-the-mask'


axios.defaults.baseURL = 'https://rio-parking.ru';
//axios.defaults.baseURL = 'http://localhost';
createApp(App).use(store).use(router).use(VueTheMask).mount('#app')
